@import 'settings.scss';

#cookieInfo, * {
  margin: 0px;
  padding: 0px;
}

#cookieInfo {
  position: fixed;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, auto);
  justify-items: center;
  align-items: center;
  width: 100%;
  height: auto;
  bottom: 0px;
  right: 0px;
  padding: 10px 40px;

  background-color: $light-main-color;
  color: $dark-main-color;
  z-index: 1000;

  p{
    grid-column: 1/ span 2;
    padding-bottom: 10px;

    font-family: 'Roboto';
    font-size: .7rem;
    line-height: 1rem;
    text-align: center;
  }

  a{
    grid-row: 2/3;
    padding: 10px 20px;

    background-color: $main-color;
    border-radius: 20px;

    color: #ffffff;
    font-family: 'Roboto';
    font-size: .7rem;
    text-align: center;
    text-decoration: none;

    transition: .3s;
  }

  .link{
    grid-column: 1/2;
  }

  .accept{
    grid-column: 2/3;
  }
}

#cookieInfo .accept:hover {
  background-color: darken($color: $main-color, $amount: 10%);
}

.cookieWindow{
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90vw;
  height: 80vh;
  padding: 100px 50px 30px;
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
  z-index: 20;

  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.432);

  &::after {
    @include before(5%, 87%, 100px, auto, auto, 3%, $main-color);
    z-index: 1;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 2s .5s;
  }

  &.showAfter::after {
    transform: scaleY(1);
  }

  .description{
    height: 100%;
    overflow: scroll;
    margin-left: 3%;
  }

  p{
    margin-bottom: 1rem;
    color: $dark-main-color;
    font-family: "Roboto", sans-serif;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  ul{
    margin-left: 2rem;
    margin-bottom: 1rem;

    li{
      color: $dark-main-color;
      font-family: "Roboto", sans-serif;
      font-size: 1.1rem;
      line-height: 1.5rem;
    }
  }

  .cookieWindowClose{
    position: fixed;
    top: 20px;
    right: 20px;
    font-weight: normal;

    color: $main-color;
    cursor: pointer;

    &::before{
      font-family: "Font Awesome 5 Free";
      content: "\f057";
      font-size: 30px;
      width: 100%;
      height: 100%;
    }
  }
}

.cookieWindowShadow{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $dark-main-color;
  z-index: 10;

  opacity: .8;
}

@media (width > 765px){
  .cookieWindow{
    width: 60vw;
  }
}
@media (width > 1200px){
  .cookieWindow{
    width: 50vw;
  }
}


@media (width > 922px){
  #cookieInfo{
    grid-template-columns: auto repeat(2, max-content);
    grid-template-rows: auto;
    justify-items: start;
    column-gap: 20px;

    p{
      font-size: 0.9rem;
      grid-column: 1 / 2;
      grid-row: auto;
      padding: 0;
    }

    a{
      font-size: 0.8rem;
      justify-self: end;
    }

    .link{
      grid-column: 2/3;
      grid-row: auto;
    }

    .accept{
      grid-column: 3/4;
      grid-row: auto;
    }
  }
}

@media (width > 1200px){
  .cookieWindow{
    padding: 100px 100px 30px;
  }
}