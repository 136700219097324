@import "settings";

#services {
  // width: 100vw;
  overflow: hidden;

  .heading {
    span {
      margin-right: 0.2rem;
    }
  }

  .description {
    display: none;
  }

  .textWrapper {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    padding: 25px 0 0;
    border-top: 2px solid $light-main-color;
    border-bottom: 2px solid $light-main-color;
    z-index: 2;

    .card {
      @include center;
      position: relative;
      cursor: pointer;
      width: 100%;
      height: 200px;
      border: none;
      margin-bottom: 30px;
      border-radius: 10px;
      overflow: hidden;
      background-size: 120% auto;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(50%);
      opacity: 0;
      transition: all 0.5s ease;

      &::before {
        @include before(100%, 100%, 0, 0, auto, auto, $main-color);
        opacity: 0.8;
        mix-blend-mode: multiply;
        transition: 0.5s;
      }

      &:hover {
        background-size: 130%;
      }

      &:hover p {
        font-size: 2.2rem;
      }

      &:hover::before {
        opacity: 1;
      }

      p {
        width: 100%;
        color: white;
        font-family: "Cormorant Garamond";
        font-size: 2.5rem;
        text-align: center;
        line-height: 120%;
        text-shadow: 0px 0px 10px $dark-main-color;
        transition: 0.5s;
      }

      &.showOnScroll {
        transform: translateY(0);
        opacity: 1;
      }
    }

    .PrawoRodzinne {
      background-image: url(../../../../images/PrawoRodzinne1.jpg);
    }

    .PrawoSpadkowe {
      background-image: url(../../../../images/PrawoSpadkowe.jpg);
    }

    .PrawoCywilne {
      background-image: url(../../../../images/PrawoCywilne2.jpg);
    }

    .PrawoPracy {
      background-image: url(../../../../images/PrawoPracy1s.jpg);
    }

    .PrawoOswiatowe {
      background-image: url(../../../../images/PrawoOświatowe1s.jpg);
    }

    .PrawoKarne {
      background-image: url(../../../../images/PrawoKarne.jpg);
    }

    .PrawoAdministracyjne {
      background-image: url(../../../../images/Administracja1s.jpg);
    }

    .Negocjacje {
      background-image: url(../../../../images/Negocjacje.jpg);
    }

    .Wspolpraca {
      background-image: url(../../../../images/Wspolpraca.jpg);
    }
  }
}

@media (width > 765px) {
  #services {
    .heading {
      span {
        &:after {
          right: -10px;
        }
      }
    }

    .textWrapper {
      .card {
        width: 45%;
      }
    }
  }
}

@media (width > 992px) {
  #services {
  padding-bottom: 5em;

    .textWrapper {
      .card {
        width: 30%;
      }

      .PrawoSpadkowe {
        transition-property: transform, opacity, background-size;
        transition-duration: 1s, 1s, 1s;
        transition-delay: 0.2s, 0.2s, 0s;
      }

      .PrawoCywilne {
        transition-property: transform, opacity, background-size;
        transition-duration: 1s, 1s, 1s;
        transition-delay: 0.4s, 0.2s, 0s;
      }

      .PrawoOswiatowe {
        transition-property: transform, opacity, background-size;
        transition-duration: 1s, 1s, 1s;
        transition-delay: 0.2s, 0.2s, 0s;
      }

      .PrawoKarne {
        transition-property: transform, opacity, background-size;
        transition-duration: 1s, 1s, 1s;
        transition-delay: 0.4s, 0.2s, 0s;
      }

      .Negocjacje {
        transition-property: transform, opacity, background-size;
        transition-duration: 1s, 1s, 1s;
        transition-delay: 0.2s, 0.2s, 0s;
      }

      .Wspolpraca {
        transition-property: transform, opacity, background-size;
        transition-duration: 1s, 1s, 1s;
        transition-delay: 0.4s, 0.2s, 0s;
      }
    }
  }
}

@media (width > 992px) {
  #services {
    .textWrapper {
      width: 80%;
    }
  }
}

@media (width > 1600px) {
  #services .textWrapper{
    width: 70%;
  }
}

@media (width > 2000px) {
  #services .textWrapper{
    width: 60%;
  }
}
