@import 'settings';

nav {
  position: fixed;
  top: 0;
  width: 100vw;
  height: $nav-height;
  z-index: 100;

  .showDesktop {
    display: none;
  }
}

.mobileMenu {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(255, 255, 255);
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));

  .logoMark {
    position: relative;
    display: inline-block;
    top: 0;
    left: 2%;
    height: $nav-height;
    transition: .5s;
    z-index: 200;

    img {
      height: 100%;
      width: auto;
    }
  }

  .topBar {
    position: relative;
    display: inline-block;
    width: auto;
    height: 100%;
    margin-right: 2%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 99;

    #nav-icon {
      width: 50px;
      height: 50px;
      position: relative;
      margin: 0 5%;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
    }

    #nav-icon span {
      display: block;
      position: absolute;
      height: 5px;
      width: 35px;
      background: rgb(74, 78, 78);
      border-radius: 9px;
      opacity: 1;
      left: 7px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }

    #nav-icon span:nth-child(1) {
      top: 10px;
    }

    #nav-icon span:nth-child(2),
    #nav-icon span:nth-child(3) {
      top: 22px;
    }

    #nav-icon span:nth-child(4) {
      bottom: 10px;
    }

    #nav-icon.open span:nth-child(1) {
      top: 15px;
      width: 0%;
      left: 50%;
    }

    #nav-icon.open span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    #nav-icon.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    #nav-icon.open span:nth-child(4) {
      top: 15px;
      width: 0%;
      left: 50%;
    }


  }

  .slideMenu {
    position: absolute;
    top: 60px;
    width: 100vw;
    height: 100vh;
    background: white;
    transform: translateY(-100%);
    transition: transform .5s, height .2s;
    overflow: scroll;
    z-index: 1;

    .colorLine {
      @include object(35.2px, 100%, $main-color);
      @include transPos(0, auto, auto, 2%, null, null);
      z-index: 100;
    }

    .menu {
      position: fixed;
      width: 100vw;
      right: 0;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 10vh;

      .menuLink {
        height: auto;
        text-decoration: none;
        color: $main-color;
        color: $dark-main-color;
        margin-bottom: 2rem;

        p {
          position: relative;
          font-family: 'Cormorant' 'Garamond';
          text-transform: uppercase;
          font-weight: 900;
          font-size: 1.2rem;
          margin-bottom: 0;
          // letter-spacing: 0px;

          &::first-letter {
            // font-family: Great Vibes;
            font-weight: normal;
            font-size: 200%;
            line-height: 100%;
            // color: white;
            margin-right: -.1rem;
            // margin-right: .1rem;
          }

          &::after {
            @include before(70%, 1px, auto, 15%, 0px, auto, $main-color);
            transform: scaleX(0);
            transform-origin: center;
          }

          &:hover {
            text-decoration: none;
          }
        }

      }
    }
  }
}

.desktopMenu {
  @include object(100%, auto, white);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.1));

  .logoHorizontal {
    position: relative;
    top: 0;
    left: 1%;
    height: 50px;
    margin: .5rem;
    transition: .5s;
    z-index: 200;
  }

  .menu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: auto;

    .menuLink {
      height: auto;
      text-decoration: none;
      color: $dark-main-color;
      margin-right: 5vw;
      border: 1px solid transparent;

      p {
        position: relative;
        font-family: Cormorant Garamond;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 1.1rem;
        margin-bottom: 0;
        padding: 5px 0px;
        letter-spacing: 1px;
        border: 1px solid transparent;

        &::first-letter {
          font-weight: normal;
          font-size: 150%;
          line-height: 100%;
          margin-right: -0rem;
        }

        &::after {
          @include before(70%, 1px, auto, 15%, 0px, auto, $main-color);
          transform: scaleX(0);
          transform-origin: center;
          transition: .5s;
        }

        &:hover {
          color: lighten($color: $dark-main-color, $amount: 20%);
          text-decoration: none;
        }

        &:hover::after {
          transform: scaleX(1);
        }
      }
    }

    & .menuLink:nth-child(5) {
      margin-right: 2vw;
    }
  }
}

.slideMenu.toggleMenu {
  transform: translateY(0vh);
  z-index: 1;
}

.slideMenu .menu .menuLink p.showAfter:after {
  transform: scaleX(1);
  transform-origin: center;
  transition: .6s;
  transition-delay: .5s;
}

@media (width > 992px) {
  nav {
    .showDesktop {
      display: flex;
    }

    .showMobile {
      display: none;
    }
  }
}