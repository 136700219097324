@import "settings";

body,
html,
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Great Vibes", cursive;
  font-family: "Roboto", sans-serif;
  font-family: "Cormorant Garamond", serif;
}

::-webkit-scrollbar {
  display: none;
}

section {
  display: flex;
  flex-flow: column nowrap;
  justify-content: start;
  align-items: center;
  margin-top: 5em;
}

.heading {
  position: relative;
  @include center;
  width: 100%;
  padding: 2rem 0;
  text-align: center;
  color: $dark-main-color;
  z-index: 2;

  h2 {
    display: inline-block;
    font-family: "Cormorant Garamond";
    margin-bottom: 0;
    font-size: 2.5rem;
    font-weight: 400;
  }

  span {
    position: relative;
    color: white;
    font-size: 130%;
    margin-right: 0.5rem;
    font-family: "Great Vibes";

    &::after {
      @include before(57px, 130%, auto, auto, 7%, -8%, $main-color);
      z-index: -1;
    }

    &::before {
      @include before(40vw, 2px, auto, auto, 7%, 0%, $main-color);
      z-index: -1;
      transform: scaleX(0);
      transform-origin: left;
    }
  }

  &.showOnScroll span::before {
    transform: scaleX(1);
    transition: 1s;
    transition-delay: 0.5s;
  }
}

.textWrapper {
  width: 90%;
  margin: 0 auto;

  h2 {
    position: relative;
    width: 100%;
    margin: 0;
    font-size: 1.5rem;
    line-height: 2rem;
    color: $dark-main-color;
    text-align: left;
    font-weight: 900;
    font-family: 'Cormorant Garamond';
  }

  p {
    position: relative;
    font-family: 'Roboto';
    line-height: 1.5rem;

    span {
      font-family: 'Roboto';
      font-size: 1.2rem;
    }
  }
}

@media (width > 765px) {
  .heading {
    position: relative;
    width: 100%;
    text-align: center;
    color: $dark-main-color;
    z-index: 2;

    h2 {
      font-family: "Cormorant Garamond";
      font-size: 3rem;
      margin-left: 0;
    }

    span {
      &::after {
        @include before(70px, 130%, auto, auto, 7%, -10%, $main-color);
        z-index: -1;
      }

      &::before {
        @include before(30vw, 2px, auto, auto, 7%, 0%, $main-color);
        z-index: -1;
      }
    }
  }
}

@media (width > 992px) {
  .heading {
    position: relative;
    width: 100%;
    text-align: center;
    color: $dark-main-color;
    display: flex;
    z-index: 2;

    h2 {
      font-family: "Cormorant Garamond";
      font-size: 3rem;
      margin-left: 0;
    }

    span {
      &::after {
        @include before(70px, 130%, auto, auto, 7%, -10%, $main-color);
        z-index: -1;
      }
    }
  }

  .textWrapper {
    h2 {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
}

@media (width > 1600px) {
  section{
    margin: 0 auto;
    padding: 0;
    padding-top: 5%;
  }
  .heading {
    margin-bottom: 50px;

    h2 {
      margin-right: 15%;
    }
  }
}