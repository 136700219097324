@import "settings";

#aboutUs {
  position: relative;
  overflow: hidden;

  .textWrapper {
    position: relative;
    margin-top: 1rem;

    h1{
      width: 100%;
      font-size: 1.5rem;
      line-height: 2rem;
      color: $dark-main-color;
      
    }

    p{
      margin-top: 20px;
    }

    .imageWrapper {
      position: relative;
      width: 100%;
      height: 300px;
      margin: 2rem 0;

      &::before {
        @include before(15%, 100%, 0%, auto, auto, 0%, $main-color);
        z-index: -1;
        transform: scaleY(0);
        transform-origin: top;
        z-index: 1;
      }

      .oNasImg2 {
        display: none;
      }

      .oNasImg1 {
        position: absolute;
        height: auto;
        max-height: 100%;
        width: auto;
        max-width: 95%;
        bottom: 0;
        left: 5%;
        z-index: 2;
        opacity: 0;
        transform: translateX(10%);
        transition: 0.7s;
        transition-delay: 0.5s;

        &.showOnScroll {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }

    &.showOnScroll ::before {
      transform: scaleY(1);
      transition: 1s;
      transition-delay: 0.5s;
    }
  }
}

@media (width > 765px) {
  #aboutUs {
    &::before {
      background-size: auto 100%;
      background-position: bottom 0 right 15%;
    }

    .textWrapper {
      padding: 0 5%;
      margin-top: 1.5rem;

      h1{
        font-size: 1.8rem;
        line-height: 2.2rem;
        color: $dark-main-color;
      }

      .imageWrapper {
        height: 400px;
      }
    }
  }
}

@media (width > 1200px) {
  #aboutUs{
    padding-bottom: 5em;

    .textWrapper{
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto auto;
      column-gap: 30px;
      width: 100%;
      margin-top: 2rem;
      padding: 0 10%;

      &::before {
        @include before(7%, 100%, 0%, auto, auto, 0%, $main-color);
        z-index: -1;
        transform: scaleY(0);
        transform-origin: top;
        z-index: 1;
      }

      &.showOnScroll::before {
        transform: scaleY(1);
        transition: 1s;
        transition-delay: 0.5s;
      }

      h1{
        grid-column: 1/span 2;
        grid-row: 1/1;
        font-size: 2.2rem;
        line-height: 2.7rem;
      }

      .imageWrapper{
        grid-column: 2/2;
        grid-row: 3/ span 4;
        align-self: end;
        margin: 0;
        height: 100%;

        .oNasImg1{
          height: 100%;
          max-width: fit-content;
          left: 0;

        }

        &::before{
          display: none;
        }
      }

      p:nth-of-type(1){
        grid-column: 1/ span 2;
        grid-row: 2/2;
      }

      p:nth-of-type(2){
        grid-column: 1/1;
        grid-row: 3/3;
        margin-top: 20px;
      }

      p:nth-of-type(3){
        grid-column: 1/1;
        grid-row: 4/4;
      }
    }
  }
}

@media (width > 1600px) {
  #aboutUs{
    .textWrapper{
      column-gap: 50px;
      padding: 0 25%;

      &::before {
        @include before(5%, 100%, 0%, auto, auto, 17%, $main-color);
      }

      &.showOnScroll::before {
        transform: scaleY(1);
        transition: 1s;
        transition-delay: 0.5s;
      }

      h1{
        grid-column: 1/span 1;
        grid-row: 1/1;
      }

      .imageWrapper{
        grid-column: 2/2;
        grid-row: 2/ span 4;
        align-self: end;
        margin: 0;
        height: 100%;

        .oNasImg1{
          height: 100%;
          max-width: fit-content;
          left: 0;

        }

        &::before{
          display: none;
        }
      }

      p:nth-of-type(1){
        grid-column: 1/ span 1;
        grid-row: 2/2;
      }
    }
  }
}

@media (width > 2200px) {
  #aboutUs{
    .textWrapper{
      column-gap: 100px;

      h2{
        grid-column: 1/span 1;
        grid-row: 1/1;
      }

      .imageWrapper{
        grid-column: 2/2;
        grid-row: 1/ span 4;
        align-self: end;
        margin: 0;
        height: 100%;

        .oNasImg1{
          height: 100%;
          max-width: fit-content;
          left: 0;

        }

        &::before{
          display: none;
        }
      }

      p:nth-of-type(1){
        grid-column: 1/ span 1;
        grid-row: 2/2;
      }
    }
  }
}
