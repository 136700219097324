@import 'settings';

.popUpWindow{
  position: fixed;
  top: 50%;
  left: 50%;
  width: 90vw;
  height: 80vh;
  padding: 100px 10px 30px;
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
  z-index: 20;

  background-color: white;
  border-radius: 10px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.432);
  overflow: hidden;


  .description{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 10%;
    overflow: scroll;

    &::after {
      @include before(5%, 100%, 0px, auto, auto, 0%, $main-color);
      position: fixed;
      z-index: 1;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 2s .5s;
    }

    &.showAfter::after {
      transform: scaleY(1);
    }
  }

  p{
    margin-bottom: 1rem;
    color: $dark-main-color;
    font-family: "Roboto", sans-serif;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  ul{
    margin-left: 2rem;
    margin-bottom: 1rem;

    li{
      color: $dark-main-color;
      font-family: "Roboto", sans-serif;
      font-size: 1.1rem;
      line-height: 1.5rem;
    }

  }

  .popUpWindowClose{
    position: fixed;
    top: 20px;
    right: 20px;
    font-weight: normal;

    color: $main-color;
    cursor: pointer;

    &::before{
      font-family: "Font Awesome 5 Free";
      content: "\f057";
      font-size: 30px;
      width: 100%;
      height: 100%;
    }
  }
}

.popUpWindowShadow{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $dark-main-color;
  z-index: 10;

  opacity: .8;
}

@media (width > 765px){
  .popUpWindow{
    width: 60vw;
  }
}
@media (width > 1200px){
  .popUpWindow{
    width: 50vw;
    height: 50vh;
    // padding: 100px 100px 30px;
  }
}
