@import 'settings';

header{
  position: relative;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  height: 100svh;
  @include center;
  flex-flow: column nowrap;
  overflow: hidden;

  .headerBG{
    position: relative;
    left: 5%;
    height: 100%;
  }

  .logoMark{
    position: absolute;
    width: 150px;
    max-width: 35%;
    bottom: 27%;
    z-index: 2;
    opacity: 0;

    animation: scaleDownFadeIn;
    animation-duration: 3s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
  }

  .mainLogoText{
      position: absolute;
      width: 80%;
      margin: 0 auto;
      bottom: 20%;
      opacity: 0;

      z-index: 3;
      filter: drop-shadow(0px 0px 10px rgb(50, 50, 50));

      animation: fadeIn;
      animation-duration: 1s;
      animation-delay: 2s;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
  }

  .imgWrapper{
    position: absolute;
    width: 80%;
    height: 400px;
    left: 0;
    bottom: 10%;
    overflow: hidden;
    background-color: #fff;
  }

  .showDesktop{
    display: none;
  }
}

@media (width > 576px) {
  header{
    .logoMark{
      bottom: 35%;
    }
  }
}

@media (width > 992px) {
  header{
    .headerBG{
      object-fit: cover;
      width: 100%;
      height: 100%;
      left: 0;
    }

    .logoMark{
      bottom: 35%;
    }

    .mainLogoText{
      bottom: 15%;
    }

    .showDesktop{
      display: block;
    }

    .showMobile{
      display: none;
    }
  }
}

@media (width > 1200px){
  header{
    .mainLogoText{
      width: 60%;
      bottom: 20vh;
    }
  }
}

@media only screen and (orientation: landscape) {
  header{
    .logoMark{
      height: 30vh;
      bottom: 35%;
    }
  }
}
