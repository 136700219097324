@import "settings";

footer{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-color: $light-main-color;
    height: 60px;
    z-index: 2;

    p{
        text-transform: uppercase;
        color: white;
        font-size: .5rem;
        margin: 0 auto 5px;
        letter-spacing: 2px;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.281);
    }

    h2{
        color: $dark-main-color;
        font-weight: bolder;
        text-transform: uppercase;
        font-size: .8rem;
        letter-spacing: 4px;
        margin: 0;
    }

}