@import "settings";

#team {
  width: 100vw;
  overflow: hidden;

  .heading {
    span {
      margin-right: 1.2rem;
    }
  }

  .mainWrapper {
    width: 100%;
    padding: 6em 0;
    background-color: lighten($color: $light-main-color, $amount: 10%);

    .textWrapper:nth-of-type(1) {
      margin-top: 100px;
    }
  }

  .wrapperMagda {
    margin-top: 5em;
  }

  .mainImgSolo {
    position: relative;
    width: 250px;
    height: 250px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    transform: translateY(25%);
    opacity: 0;
    z-index: 3;

    &.showOnScroll {
      transform: translateY(0);
      opacity: 1;
      transition: transform 1s, opacity 1s;
      transition-delay: 0.5s;
    }

    img {
      position: absolute;
      height: 100%;
      object-fit: cover;
    }
  }

  .mainImgSoloEwa {
    background-image: url(../../../../images/EWAksiążki4.jpg);
    background-size: auto 100%;
    background-position: top 0 left 30%;
  }

  .mainImgSoloMagda {
    background-image: url(../../../../images/MagdaKsiążkiKolor4.jpg);
    background-size: auto 100%;
    background-position: top 0 left 20%;
  }

  .textWrapper {
    position: relative;
    @include center;
    flex-flow: column nowrap;
    justify-content: start;
    margin: -100px auto 0px;
    padding: 5%;
    background-color: white;
    border-radius: 10px;
    z-index: 2;

    &::before {
      @include before(100%, 100%, 0, 0, auto, auto, null);
      background-size: contain;
      background-position: top 20px right 0;
      background-repeat: no-repeat;
      border-radius: 10px;
      opacity: 0.1;
      z-index: 1;
    }

    .titleName {
      width: 90%;
      margin: 120px auto 0px;
      padding: 15px 0px 10px;
      color: $dark-main-color;
      line-height: 150%;
      transform: translateX(150%);
      transform-origin: left;
      z-index: 3;

      h2 {
        margin-bottom: 5px;
        font-size: 1.8rem;
      }

      .name {
        font-size: 2rem;

        &:first-letter {
          font-family: "Great Vibes";
          font-size: 130%;
          margin-right: 3px;
        }
      }

      .surname {
        margin-left: 10px;
      }

      span {
        font-size: 120%;
        margin-right: 1px;
      }

      &.showOnScroll {
        transform: translateX(0);
        transition: transform 1s;
        transition-delay: 0s;
      }
    }

    .text {
      position: relative;
      align-self: flex-end;
      width: 90%;
      text-align: left;
      z-index: 3;

      &::after {
        @include before(5px, 98%, 5px, auto, auto, -25px, $main-color);
        z-index: 1;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 2s;
        transition-delay: 1s;
      }

      &.showOnScroll::after {
        transform: scaleY(1);
      }

      p:nth-of-type(1) {
        margin-bottom: 15px;
      }
    }
  }

  .textWrapperEwa {
    &::before {
      background-image: url(../../../../images/Ewa.png);
    }
  }

  .textWrapperMagda {
    &::before {
      background-image: url(../../../../images/Magda.png);
    }
  }
}

@media (width > 765px) {
  #team {
    .mainImgSolo {
      width: 300px;
      height: 300px;
      margin-left: 10%;
    }

    .textWrapper {
      .titleName {
        margin-top: 90px;
      }
    }
  }
}

@media (width > 992px) {
  #team {
    .textWrapper {
      margin-top: -130px;

      .titleName {
        @include center;
        flex-flow: column nowrap;
        margin-top: 0;
        margin: 0 auto;
        margin-bottom: 20px;

        h2 {
          display: inline-block;
          width: 70%;
          margin: 0 auto;
          text-align: right;
        }

        .name {
          font-size: 2.5rem;
        }

        .surname {
          margin: auto;
          font-size: 2.2rem;
        }
      }

      .text::after {
        width: 20px;
        left: -50px;
      }
    }

  }
}

@media (width > 1600px) {
  #team {
    .mainWrapper {
      @include center;
      flex-flow: row nowrap;
      align-items: start;
      padding: 50px 10%;
    }

    .textWrapper {
      min-height: 750px;

      .titleName {
        margin-top: 50px;
        align-items: end;

        h2,
        .surname {
          margin: 0;
          margin-right: 5%;
        }
      }
    }

    .wrapperMagda {
      margin-top: 0;
    }
  }
}